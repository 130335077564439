import { $W } from '@wix/blocks-widget-services-types';
import model from './model';
import { BUTTON } from '../../assets/constants/variables';
import { clickOnButtonOrLink } from '@wix/bi-logger-wixlabs-ugc/v2';
import webBiLogger from '@wix/web-bi-logger';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  let myParams = { ...$widget.props, url: '' };
  const logger = webBiLogger.factory().logger();
  const { instanceId, appDefinitionId } = flowAPI.controllerConfig.appParams;
  const { bi } = flowAPI.controllerConfig.platformAPIs;
  const updateEmbedParams = (props) => {
    myParams = {
      ...$widget.props,
      ...props,
    };
    $w('#text1').text = props.fileName;
    $w('#button1').buttonLabel = props.fileName;
    $w('#button1').label = props.fileName;
    setLink();
  };
  function setLink() {
    $w('#button1').link = myParams.url;
    $w('#button1').target = '_blank';
    logger.report(
      clickOnButtonOrLink({
        app_id: appDefinitionId,
        biToken: bi?.metaSiteId,
        instance_id: instanceId,
        comp_id: instanceId,
        type: 'LIGHT_BOX',
        description: 'view_doc',
        position: 0,
      }),
    );
  }
  const postMessageReader = (action) => {
    if (flowAPI.environment.isEditor || myParams.embedMode !== BUTTON) {
      return;
    }
    return;
  };

  return {
    pageReady: async ($win: $W) => {
      $widget.fireEvent('widgetLoaded', {});
      setLink();
    },
    exports: { postMessageReader, updateEmbedParams },
  };
});
