import { $W } from '@wix/blocks-widget-services-types';
import model from './model';
import webBiLogger from '@wix/web-bi-logger';
import { statusChange } from '@wix/bi-logger-wixlabs-ugc/v2';

export default model.createController(
  ({ $bind, $widget, flowAPI, $w, controllerConfig }) => {
    let onChangeCalled = false;
    const logger = webBiLogger.factory().logger();
    const { instanceId, appDefinitionId } = flowAPI.controllerConfig.appParams;
    const { bi } = flowAPI.controllerConfig.platformAPIs;
    const { t } = flowAPI.translations;
    if ($widget.props.fileName === '') {
      $widget.props.fileName = t('app.settings.gfpp.pdf.name');
    }
    return {
      pageReady: async ($win: $W) => {
        try {
          $widget.fireEvent('widgetLoaded', {});
          if ($widget.props.embedMode === 'LIGHT_BOX') {
            $bind('#multiStateBox1', { currentState: () => 'Button' });
          } else {
            $bind('#multiStateBox1', { currentState: () => 'Preview' });
          }
          $win.onReady(() => {
            logger.report(
              statusChange({
                app_id: appDefinitionId,
                comp_id: controllerConfig.compId,
                instance_id: instanceId,
                biToken: bi?.metaSiteId,
                new_status: '',
                old_status: '',
                type: $widget.props.embedMode,
              }),
            );
            if ($widget.props.embedMode === 'LIGHT_BOX') {
              $bind('#multiStateBox1', { currentState: () => 'Button' });
              $win('#button1').updateEmbedParams &&
                $win('#button1').updateEmbedParams({ ...$widget.props });
            } else {
              $bind('#multiStateBox1', { currentState: () => 'Preview' });
              if (!onChangeCalled) {
                $win('#preview1').updateEmbedParams &&
                  $win('#preview1').updateEmbedParams({ ...$widget.props });
              }
            }
          });
          $widget.onPropsChanged((oldProps, newProps) => {
            if (newProps.fileName === '') {
              newProps.fileName = t('app.settings.gfpp.pdf.name');
            }
            if (oldProps.fileName === '') {
              oldProps.fileName = t('app.settings.gfpp.pdf.name');
            }
            const {
              variantId: newVariantId,
              url: newUrl,
              fileName: newFileName,
              defaultViewMode: newDefaultViewMode,
              showAnnotationTools: newShowAnnotationTools,
              startPage: newStartPage,
              localeLang: newLocaleLang,
              setZoomPercentage: newSetZoomPercentage,
            } = newProps;
            const {
              variantId: oldVariantId,
              url: oldUrl,
              fileName: oldFileName,
              defaultViewMode: oldDefaultViewMode,
              showAnnotationTools: oldShowAnnotationTools,
              startPage: oldStartPage,
              localeLang: oldLocaleLang,
              setZoomPercentage: oldSetZoomPercentage,
            } = oldProps;
            onChangeCalled = true;
            if (JSON.stringify(oldProps) !== JSON.stringify(newProps)) {
              if (newProps.embedMode === 'LIGHT_BOX') {
                $win('#multiStateBox1').changeState('Button');
                $win('#button1').updateEmbedParams &&
                  $win('#button1').updateEmbedParams({ ...newProps });
              } else {
                $win('#multiStateBox1').changeState('Preview');
                if (
                  newVariantId !== oldVariantId ||
                  newUrl !== oldUrl ||
                  newFileName !== oldFileName ||
                  newDefaultViewMode !== oldDefaultViewMode ||
                  newShowAnnotationTools !== oldShowAnnotationTools ||
                  newStartPage !== oldStartPage ||
                  oldLocaleLang !== newLocaleLang ||
                  oldSetZoomPercentage !== newSetZoomPercentage
                ) {
                  $win('#preview1').updateEmbedParams &&
                    $win('#preview1').updateEmbedParams({ ...newProps });
                  $win('#preview1').postMessageReader &&
                    $win('#preview1').postMessageReader('dataChange');
                }
              }
            }
          });
        } catch (e) {
          console.log('Container error in onPropsChanged ', e);
        }
      },
      exports: {},
    };
  },
);
