import { $W } from '@wix/blocks-widget-services-types';
import model from './model';
import { localeCode } from '../../assets/constants/variables';
import { debugMessage } from '../../assets/helpers/helpersFunctions';

export default model.createController(({ $widget, flowAPI, $w, $bind }) => {
  const language: any = flowAPI.environment.language;
  const languageValue = localeCode[language] ?? localeCode.en;
  let myParams = { ...$widget.props, url: '' };
  let onMessageListener = false;
  let sendingDataSent = false;
  let postMessageReader1;
  let currentAction = '';

  const updateEmbedParams = (props) => {
    myParams = {
      ...$widget.props,
      ...props,
    };
  };
  const postMessageReader = (action) => {
    debugMessage('pdfviewer', 'postMessageReader', action);
    currentAction = action;
    postMessageReader1(action);
  };
  return {
    pageReady: async ($win: $W) => {
      $widget.fireEvent('widgetLoaded', {});
      const listenerHandler = (event: { data: { action: string } }) => {
        debugMessage(
          'pdfviewer',
          'listenerHandler',
          'getting data',
          currentAction,
          event.data.action,
        );
        if (event.data.action === 'giveMeData') {
          $win('#html1').postMessage({
            ...myParams,
            newUrl: myParams.url,
            localeLang: languageValue,
            currentAction,
          });
          sendingDataSent = true;
        }
      };

      $win.onReady(() => {
        try {
          if (!sendingDataSent && myParams.url !== '') {
            const data = {
              ...myParams,
              newUrl: myParams.url,
              localeLang: languageValue,
              action: 'sendingData',
            };
            debugMessage('pdfviewer', ' $win.onReady', 'sending data', data);
            $win('#html1').postMessage(data);
            sendingDataSent = true;

            if (!onMessageListener) {
              $win('#html1').onMessage(listenerHandler);
              onMessageListener = true;
            }
          }
        } catch (e) {
          console.log('preview element was not ready yet', e);
        }
      });
      postMessageReader1 = (action: string) => {
        debugMessage('pdfviewer', 'postMessageReader1', 'init', action);

        if (action === 'sendingData') {
        } else {
          const data = {
            ...myParams,
            newUrl: myParams.url,
            localeLang: languageValue,
            action,
          };
          debugMessage('pdfviewer', 'postMessageReader1', 'sending data', data);
          $win('#html1').postMessage(data);
        }
      };
    },
    exports: { postMessageReader, updateEmbedParams },
  };
});
